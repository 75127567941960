.hero-img{
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 85vh;
  position: relative;
  margin-bottom: 0%;
/* margin: 0rem 3rem; */
  /* padding:0rem 3rem;
   */
   padding: 0rem 3rem;
  border-bottom: 2px solid #070606;
}
 

.hero-img::before{
    content: "";
    background: url("https://img.freepik.com/free-vector/physiotherapy-rehabilitation-people-flat-icons-collection_1284-15880.jpg?w=2000");
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1 ;

}
.headin{
  color: rgb(243, 237, 221);
  position: absolute;
  font-weight: 500;
  font-size: 3em;
  top: 58%;
  left: 17%;
}



.heading{
 width: 100%;
 height: 100%;
  display: flex;
  color:#070606;

}
.hero-img h1 {
    font-size: 2.4em;
    text-align: center;
}
.hero-img p {
    font-size: 1.4rem;
    /* text-align: center; */
} 
.text1{   position: absolute;
  top: 40%;
  left: 50%;
  text-align: center;
  transform: translate(-50%,-50%);}
.text1 h1{
  font-size: .6rem;
  padding: 0.6rem 0 1.5rem;
  font-weight: 500;
}
.text1>p {
  font-size: 1.2px;
  font-weight: 300;
  }
@media  screen and (max-width:640px) {
 .hero-img h1{ 
    font-size: 2rem;
 }   
 .headin{
  color: rgb(243, 237, 221);
  position: absolute;
  font-weight: 450;
  font-size: 2rem;
letter-spacing: -.5px;
  top: 47%;
  left: 10%;
}

.hero .content h1{
  font-size: 1.5rem;
  color: rgb(243, 237, 221);
}
.hero .content p {
  font-size: 1rem;
  
}
.hero .content{
  margin-top: -10rem;
}



.hero-img::before{
  content: "";
  background: url("https://img.freepik.com/free-vector/physiotherapy-rehabilitation-people-flat-icons-collection_1284-15880.jpg?w=2000");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1 ;
}
.hero-img{
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 40vh;
  position: relative;
  /* margin-bottom: 0%; */
   /* padding: 0rem 3rem; */
  border-bottom: 2px solid #070606;
}
 

}