.mask {
width: 100%;
height: 100vh;
position: relative;
}
.into-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;

}
.mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(12, 12, 12);
    opacity: 0.6;
}
.hero{
    height: 100%;
    width: 100%;
    padding:0rem 0rem;
    padding-bottom: 4rem;
}
.hero .content{
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%,-50%);
}
.hero .content h1{
    font-size: 4rem;
    padding: 0.6rem  1.5rem;
    color: rgb(243, 237, 221);
}
.hero .content p {
    font-size: 1.4rem;
    font-weight: 200;
    text-transform: uppercase;
     transition: cubic-bezier(0.075, 0.82, 0.165, 1); 
}
.content .btn{
    margin: 1rem 0.2rem;
}
@media screen and (max-width:640px){

    .hero .content h1{
        font-size: 1.5rem;
        color: rgb(243, 237, 221);
    }
    .hero .content p {
        font-size: 1rem;
        
    }
    .hero .content{
        margin-top: -10rem;
    }
    .hero>mask{
        /* margin-top: 5rem; */

    }
    .mask::after {
        content: "";
        width: 100%;
        height: 44%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(12, 12, 12);
        opacity: 0.6;
    }
    
    .into-img{
        width: 100%;
        height: 40%;
        margin-top: 2rem;
        background-size: cover;
        background-position: center;
        object-fit: cover;
       
    
    }
    
}