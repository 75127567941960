

label{
    color: rgb(10, 9, 9);
}

.form-container{
    margin : 0rem 1rem;
    display: flex;
    gap: 5rem;
    overflow: hidden;
}
.form-container .l-form h1{
    font-size: 1.8rem;
    color: #070606;
}
.form-container .l-form{

    
}
.form-container{
    padding: 0rem 5rem;
}
.form-container .l-form img
{
    width: 30rem;
    /* height: 4rem; */
    margin: 0rem 0rem;
    padding: 0rem 1rem;


}
.l-form h5{
    /* margin-top: 4rem; */
    font-size: 1.8rem;
    font-weight: 500;
    /* width: 40vw; */
    /* margin-bottom: -5rem; */
    color: grey;
}
#n h2{
    font-size: 3em;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    /* margin-left: 30%; */
    
}

.form-container .m-test{
    display: flex;
    width: 20rem;
    margin-top: 3%;
    /* position: absolute; */
    margin-right: 39%;
    /* color: red; */
    gap: 20px;
    display: grid-row;
}
.form-container .m-test .test{
    background:#EFEAE2;
    height: 15rem;
    /* width: 25rem; */
    color: #070606;
    border: 2px solid rgb(17, 17, 17);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
 
}
.form-container .m-test .test h1{
    color: red;
}
.form-container .m-test .test p{
    color: black;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
}
/* .form-container .l-form h2{
    font-size: 3rem;
    margin-top: 3rem;
} */
       
    
  form{
            display: flex;
            margin-top: 4rem;
            flex-direction: column;
               justify-content: center;
                text-align: center;
                /* margin-top: 4rem;
                margin-right: 3rem;
                margin-bottom: 0rem; */
                padding: 1rem ;
                max-width: 100%;
                border-color: rgba(255, 255, 255, 0.1);
                border: 2px solid black;
                background-color: grey;
                border-radius: 10px;
        
        }
 
 
form input{   margin-bottom: 1.5rem;
    padding: 0.5rem 0rem;
    font-size: 1rem;
    max-width: 100%;
    margin-top: 0.5rem;
    background-color: white;
    border-color: rgba(255, 255, 255, 0.1);
    color: #070606;

}
form textarea{
    margin-bottom: 1.5rem;
    padding: 0.5rem 6rem;
    font-size: 1rem;
    max-width: 100%;
    background-color: white;
    border-color: rgba(255, 255, 255, 0.1);
    color: #070606;
    }
    .form-container .l-form h4{
        font-weight: 500;
        font-size: 2.5rem;
        margin-top: 1rem;
        margin-left:1rem ;
    }

   

    .mapouter{position:relative;text-align:right;height:80vh;width:100%; padding: 2rem; }
.gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}
  

@media screen and (max-width: 640px) {
    #n h2{
        font-size: 1.2rem;
        margin-left: .5rem;
        font-weight: 800;
        letter-spacing: -.3px;
        word-spacing: -.2px;
        color: #070606;
        margin-right: 0.5rem;

    }
     .form{
       margin-left: -10rem;
        
     }
        
     
    .form-container{
     display: flex;
     flex-direction: column;
     margin-left: -8rem;
     padding: 0rem 5rem;
    }

    .form-container .l-form h5{
        font-size: 1.2rem;
        font-weight: 500;

    }
    .form-container .l-form h1{
        /* margin-left: .5rem;
         */
display: flex;
justify-content: center;
}

    .form-container .l-form img{
        width: 100%;
      
    }
    .form-container .l-form h4{
      
        display: none;
    
    }
    .form-container .form{
       
        margin: 0rem 3rem;
        width: 100vw;
        margin-top: 10rem;
     
       


    }
    form{
        margin: 1rem 1rem;
    }
     .form form input,form textarea{
        margin-bottom: 1.5rem;
        padding: 0.5rem 0rem;
        font-size: 1rem;
        max-width: 100%;
        margin-top: 0.5rem;
        background-color: white;
        border-color: rgba(255, 255, 255, 0.1);
        color: #070606;


    }
    .form-container .m-test{
  
        display: none;
    }
    .form-container .l-form{
        width: 100%;
        margin-left: 4rem;  
        margin-bottom: -13rem;
        
    }
    .l-form h5{

        margin-top: 1rem;
        font-size: 1.2rem;
        font-weight: 300;
        /* margin-left:40px ; */
        justify-content: center;
        text-align: center;
    }
    .headin{
        color: rgb(243, 237, 221);
        position: absolute;
        font-weight: 450;
        font-size: 1.7rem;
      letter-spacing: -.5px;
        top: 52%;
        left: 10%;
      }
}
