.about{
    overflow: hidden;
/* width: 100%; */
display: flex;
/* align-items: center; */
/* justify-content: center; */
flex-wrap: wrap;


flex-direction: column;
/* height: 100%; */
}
.about .abouttext{
    text-align: center;
    margin: 3rem 1rem;
}
.about .abouttext h1{
 font-size: 2em;
 color: black;
}
  
    .about .abouttext .pe{
        color: black;
        margin-bottom: -40px;
        margin-top: 60px;
    }

    .about .container{
        position: relative;
    flex-direction: column;
        display: flex;
        margin-top: 0rem;
        /* grid-template-columns: repeat(1,1fr); */
    }
     .about .leftaboutimg img{
       width: 50%;
       margin-right: 2rem;
       height: 70%;
       margin-bottom: 3rem;
       /* border-radius: 50%;
        */
        border-top-right-radius: 20%;
        border-bottom-left-radius: 20%;
       /* height: 50%; */
    }
    .aboutdr{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0rem 2rem;
        margin-bottom: 2rem;
    }
    .about  p{
        font-size: 1.5rem;
        /* color: rgb(252, 130, 9); */
        color: grey;
        margin: 1rem 0;

    }
    .leftaboutimg{
        display: flex;
        gap: 5%;
    }
    .margin{
        margin-top: 4rem;
    }
    .rightaboutimg{
        
            display: flex;
            gap: 5%;
        
    }
    .leftaboutimg h1{
        color: black;
        font-weight: 400;

    }
    .aboutdr h1{
        color: rgb(9, 9, 9);
        font-weight: 400;
        font-size: 2.4rem;
    }
    
    .about .rightaboutimg img{
        width: 50%;
        height: 50%;

        border-top-left-radius: 20%;
        border-bottom-right-radius: 20%;
    
    }
    .container .leftrightimg h1{
        color: black;
    }
    .container>.leftaboutimg h1{
    color: black;
    margin-top: 3rem;
    }
    .container>.leftaboutimg h2{
      color: rgb(131, 122, 106);
    }


    .about .acard1 {
        width: 100%;
        margin: -1rem 1rem;
        background:rgb(39, 35, 35);}
      .about  .acard1-container{
            max-width: 1140px;
            margin: auto;
            display: grid;
            grid-template-columns: repeat(5,1fr);
            grid-gap:40px; }

            .aboutreq{
                width: 60%;
                text-align: center;
                   margin-bottom: 3rem;
                   padding: 1rem 2rem;
                   margin-left: 20rem;
                   margin-right: 3rem;
                   margin-top: 4rem;
                  background:rgb(38, 33, 26);
    
                           }
                           .aboutreq h1{
                            font-weight: 300;
                            font-size: 3rem;
                           }
                    

          
 .aboutreq .aboutb{
                text-align: center;
                margin: 2rem 2rem;
            }
            @media screen and (max-width: 640px) {
                
                .aboutdr h1{
                    font-size: 1.6rem;
                }
                .rightaboutimg{
                    display: flex;
                    /* flex-direction: column;
                     */
                    width: 100%;
                   

                }
                .aboutdr p{
                    font-size: 1.2rem;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: start;
                    padding: 0rem -2rem -1rem -2rem;

                }
                   
                .about .rightaboutimg{
                  /* margin-left: 2.3rem; */
                  margin-right: -3rem;
                  display: flex;
                  flex-direction: column;
                  /* justify-content: center; */
                  align-items: center;
             
                
                }
                .about .rightaboutimg img{
                    width: 95vw;
                }
                 .about .acard1-container {
                    max-width: 80%;
                    margin: auto;
                    display: flex;
                    flex-wrap: wrap;
                 }
                 .about .container .leftaboutimg{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                 }
                 .about .leftaboutimg img{
                    /* width: 10rem; */
                    margin-left: 6rem;
                   


                 
                 }
                 .leftaboutimg{
                    display: flex;
                    flex-direction: column-reverse;
                }
                 .about .container .leftaboutimg h1{
                    font-size: 1rem;
                    /* display: flex; */
                    margin-top: 5px;
                    text-align: center;
                    display: flex;

                    margin-bottom: -10px;
                 }
                 .about .container .leftaboutimg h2{
                    font-size: 1rem;
                    /* justify-content: center; */
                    /* padding: 1rem 3rem; */
                    font-weight: 500;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    /* width: 18vw; */
                 }
                 .about .container{

                    position: relative;
                    display: grid;
                    flex-wrap: wrap;
                    display: flex;
                    /* margin: auto; */
                    /* grid-template-columns: repeat(1,1fr); */
                }
                .about .abouttext p{
                    text-align: center;
                    font-size: 1rem;
                }
                .aboutreq{
                    width: 80%;
                    margin-left: 3rem;
                   
                  
                    

                }
                .aboutreq h1{
                    font-size: 1.8rem;
                font-weight: 200;         
                }
                
         .smalll{
            font-size: .5rem;
         }
            }