@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family:  ,sans-serif;  */
}

*{
  margin:0;
  padding:0;
  box-sizing:border-box;
  font-family: "matter",  "Roboto Medium";
}
body{
  background-color: #FFF2D8;
  /* background-image: url(https://images.unsplash.com/photo-1629968417850-3505f5180761?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1952&q=80); */
}
.ds{
  filter: drop-shadow(0 0 0.60rem #113946);
}
h1,h4,p,a
{color: white;
text-decoration: none;
}
ul{
  list-style-type: none;
}

.btn {
  padding: .5rem .5rem;
  font-size: .8rem;
 text-transform: uppercase;
  background: #C2DEDC;
  color: #222;
  border: 1px solid #0d0c0c;
 font-weight: 600;
 width: auto;
  cursor: pointer;
  border-radius: 10px;
}


.btn-light {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

 .btn:hover{
  background:#1494E4;
  /* #FFFFFF */
  color: #fff;
  transition: 0.3s;
 }


 
 