.project-heading{
    text-align: center;
    font-size: 2.5rem;
    padding: 4rem 0 2rem 0;
    color: #1b1815;
}
.project-container{

    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap:40px; 
    padding-bottom: 1rem;
}
.project-card{
    background:#EAD7BB;
    padding: 1.2rem 1rem;
    border-radius: 20px;
   
    box-shadow: 5px 10px 10px #BCA37F

}
.project-card img{
    width: 100%;
    border-radius: 10px;
    height: 20vh;
}


.project-title{
    color: rgb(9, 9, 9);
    padding: 1rem 0 ;
}

.project-details p {
    padding-bottom :1rem ;
    font-size: 1.1rem;
    text-align: justify;
}
.pro-btns{
    display: flex;
    justify-content: space-between;
    align-items: bottom;
    padding: 0%;
}
.pro-btns .btn{
    padding:0.5rem 1rem
}
@media screen and (max-width: 740px) {
    .project-container{
        max-width: 80%;
        margin: 2rem 2rem;
        grid-template-columns:1fr ;

    }
    .project-card img{
        height: 12rem;
    }
    .project-heading{
        font-size: 1.2rem;
        margin: -2rem 0px;
    }
}