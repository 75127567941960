.footer{
    width: 100%;
    padding: 1rem 0;
    background-color: #113946;
    /* margin-top: 7rem; */

}
.footer-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 30px; 
}

.left{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.h{
    font-size: 2.5rem;
   margin-left: 1rem;
   
}
.p{
    /* margin-right: 1rem; */
    margin-right: 2rem ;
}
.h:hover{
    color: #EAD7BB;
    cursor: pointer;
    transition: 0.5s;
    scale: 1.2;
}

.location{
    display: flex;
    margin-bottom: 0.8rem;
}
.location p{
    /* line-height: 30px; */
}
.email{
    width: 100%;
    display: flex;
    /* line-height: 10vh; */
}
.right{
    height:100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.right h1{
    font-size:  1.5rem;
 }
.social{
    /* margin-top: 1rem; */
    margin-top: 3rem;
    margin-bottom: -4rem;
}

h4 {
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
}
p{
    font-size: 1.2rem;
    /* line-height: 20px; */
    word-spacing: 6px;
}
.last{
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 0%;
}
.fab{
    display: flex;
    gap: 2rem;
}
@media screen and (max-width: 640px){
    .footer-container{
       
        grid-template-columns:1fr;
    }
    .right{
        margin-bottom: -3em;
        margin-top: -2em;
    }
    .social{
        display: flex;
        justify-content: space-around;
        margin: 1rem;
        margin-bottom: -1rem;
    }
   
    .fab{
        display: flex;
        /* justify-content: space-between; */
        gap: 2rem;
    }
    .right h1{
        font-size:  1.2rem;
     }
     .right p{
        font-size:  .9rem;
        word-spacing: -.2px;
     }
     .last{
        font-size: .8rem;
        word-spacing: -.8px;
     }
}
    
