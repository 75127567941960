
.container{
  
    /* padding: 30px; */
    overflow: hidden;
    padding:0rem 6rem;
}
span{
    color: #113946ce;
    font-size: 2em;
}



 /* new home  */
.reverse{
    flex-direction: row-reverse;
     justify-content: flex-end;
}

 .flex{
    display: flex;
    align-items: center;
   
}

.big-feature{
    padding: 60px var(--padding-inline-section) 0;
  
}

.big-f{
    /* gap: 60px; */
}
.big-feature img{

    width: 40vw;
   
    border-radius: 20px solid black;
}
.big-feature-des{
    /* margin-left: 8vw; */
    flex-direction: column;
    align-items: flex-start;
    margin-top: -4rem;
}


.big-feature-des h2{
 margin-bottom: 3rem;
 font-size: 2em;
 font-weight: 600;
}
.big-feature-des p{
font-size: 1.6rem;
color:  rgb(56, 47, 31);

}
 /* Services card  */
 .feature-area{

    flex-wrap: wrap;
    margin-top: 3rem;
    justify-content: center;
    gap:5rem;
    margin-bottom: 3rem;

}
.service h4{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0e2d47;
    font-size: 2rem;
    margin-bottom: -30px;
    margin-top: 15px;
}
.feature-card{
    flex-direction: column;
    gap: 3px;
    text-align: center;
    max-width: 30%;
}
.feature-card p{
    font-size: 1.5rem;
    width: 20vw;
    color:  rgb(131, 122, 106);
    margin-bottom: 1rem;
    font-weight: 300;
}
.feature-card img{
    width: 3rem;
}
/* big feature sectionstart */
/* .feature-card>a{
    margin-right: 12rem;
} */










@media screen and (max-width: 640px) {
   
        .big-f{
            display: flex;
            flex-direction: column;
            gap: 0px;
            flex-wrap: wrap;

        }
        .container{
            padding: 0rem 2rem;
        }

        .feature-area{
            gap: 30px;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

       
        }
        .big-feature{
            overflow: hidden;
            margin-top: -63vh;
        }
        .big-feature-img img{
            width: 100%;
            /* height: 45vh; */
             margin: 2rem 0rem;
             border: 50px 20px;
           
        }
        .big-feature-img{
            margin-top: 1px;
            border: 50px;
        }
        .big-feature-des{
            margin-top: -30px ;
        }
        .big-feature-des h2{
               font-size: 1.4rem;
               margin-top: 1rem;
               /* margin-bottom: -.1rem; */
        }
        .big-feature-des h2 span{
            font-size: 1.5rem;
        }
        .big-feature-des p{
            font-size: 1.1rem;
            margin-top: -2.3rem;

        }

        .feature-area{
            display: flex;
          
        }
        .feature-card h3{
            width: 100%;
            font-size: 1em;

        }

        .feature-card p{
            width: fit-content;
            font-size: 0.8rem;
        }

        .btn{
            font-size: .8rem;
            padding: .4rem .8rem ;
        }
        .feature-card{
            flex-direction: column;
            gap: 1px;
            text-align: center;
            max-width: 30%;
        }

    }
